import { createRouter, createWebHistory, RouteLocationNormalized, RouterScrollBehavior } from 'vue-router'

import Tab from '@/classes/Tab'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import { apiFetchJSON } from '@/utils/api-functions'
import { addRouterErrorHandler, clearProcessedErrors } from '@/utils/error-handler'
import { firstString, getParentTableName } from '@/utils/helpers'
import Imports from '@/utils/Imports'
import { publicPath } from '@/utils/paths'
import routes from '@/utils/routes'

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return Promise.resolve(savedPosition)
  }
  if (to.hash) {
    return Promise.resolve({ el: to.hash })
  }
  // Oletuksena scrollataan sivun yläreunaan
  return Promise.resolve({ left: 0, top: 0 })
}

export function createKantoRouter() {
  const router = createRouter({
    history: createWebHistory(publicPath),
    routes: routes(),
    scrollBehavior
  })

  router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized, failure) => {
    if (failure) {
      // Keskeytynyt navigointi (esim. ConfirmLeaveDialogiin), ei tehdä mitään
      return
    }
    const fromTab = Tab.getTab(firstString(from.params.tab))
    const toTab = Tab.getTab(firstString(to.params.tab))
    if (fromTab?.isTable() && toTab && fromTab !== toTab && !fromTab.hasAncestor(toTab) && !toTab.hasAncestor(fromTab)) {
      // Navigoidaan eri tauluun joka ei ole lapsi/vanhempi -> tyhjennetään hakuehdot
      Imports.store.clearSearchTuples(fromTab)
    }
    setTuples(to, from)

    // Onistuneen navigoinnin lopuksi tyhjennetään edellisellä sivulla näytetyt virheilmoitukset,
    // jotta saadaan näytettyä virheilmoitukset uudelleen, jos sama virhe toistuu, jäämättä kuitenkaan kiinni ikuiseen virheilmoitusten looppaukseen.
    clearProcessedErrors()
  })

  router.beforeEach(async (_to: RouteLocationNormalized, _from: RouteLocationNormalized) => {
    const store = Imports.store
    if (!store.appConfig && store.appConfigPromise) {
      await store.appConfigPromise
    }
  })

  Imports.router = router
  addRouterErrorHandler(router)

  return router
}

function fetchParents(to: RouteLocationNormalized): void {
  const store = Imports.store
  if (to.params.ptable1 && !store.parentTuple) {
    // Haetaan parent-hierarkia
    let url = 'parents'
    for (let i = 1; i <= 10; i++) {
      const tabName = firstString(to.params['ptable' + i])
      const key = firstString(to.params['pkey' + i])
      if (tabName === undefined || tabName === '' || key === undefined || key === '') {
        break
      }
      url += `/${tabName}/${key}`
    }
    const tab = store.appConfig!.tabs[getParentTableName()!]
    apiFetchJSON<TupleJSON>(url)
      .then(async tupleJSON => {
        const tuple = await Tuple.fromJSON(tupleJSON, tab)

        for (let parent: Tuple | null = tuple; parent; parent = parent.parent) {
          parent.incompleteChildren = true
        }

        return tuple
      })
      .then(tuple => {
        store.$patch({ parentTuple: tuple })
        if (store.tuple) {
          store.tuple.parent = tuple
        }
      })
  }
}

/**
 * Asetetaan state.parentTuple-hierarkia, tai fetchataan se tarvittaessa.
 */
function setTuples(to: RouteLocationNormalized, from: RouteLocationNormalized): void {
  const fromTab = Tab.getTab(firstString(from.params.tab))
  const toTab = Tab.getTab(firstString(to.params.tab))
  if (fromTab && toTab && (fromTab !== toTab || from.params.key !== to.params.key)) {
    const store = Imports.store
    // Vaihdettiin routea, tutkaillaan saadaanko parent-tieto jo tiedossa olevista tupleista
    const tuple = store.tuple
    const parentTuple = store.parentTuple
    if (toTab.parentTable === fromTab) {
      // Siirrytään parent -> child
      store.$patch({ parentTuple: tuple, tuple: null })
    } else if (fromTab.hasAncestor(toTab)) {
      // Siirrytään child -> ancestor
      let ancestorTuple = tuple?.parent ?? parentTuple
      while (ancestorTuple && ancestorTuple.tab !== toTab) {
        ancestorTuple = ancestorTuple.parent
      }
      store.$patch({ parentTuple: ancestorTuple?.parent ?? null, tuple: ancestorTuple })
    } else if (fromTab.parentTable && fromTab.parentTable === toTab.parentTable) {
      // Siirrytään child -> sibling
      store.$patch({ tuple: null })
    } else {
      // Siirrytään johonkin edellisen tab-hierarkian ulkopuolelle
      store.$patch({ tuple: null, parentTuple: null })
    }
  }
  fetchParents(to)
}
