<template>
  <div class="root">
    <ul class="notifications">
      <InviteBanner v-for="invite in invites" :key="invite.getUniqueId()" :tuple="invite" @refresh="refresh" />
    </ul>
    <div class="safeboxes">
      <div class="main-safebox-grid-container">
        <SafeboxGrid :model-value="nonGroupedSafeboxes" @select="onSelect" />
      </div>
      <SafeboxGroup v-for="group in groups" :key="group.getKeyString()" :group="group" />
    </div>
    <div style="flex-grow: 1" />
    <!--<div class="version">Versio {{ version }}</div>-->
    <div class="bottom-bar">
      <q-btn flat icon="fas fa-plus" no-caps rounded class="create-group" @click="createGroup">{{ $t('SafeboxListing.createGroup') }}</q-btn>
      <SafeboxSearch ref="search" />
      <q-btn v-if="showCreateButton" flat icon="fas fa-plus" no-caps rounded class="manage-safeboxes" @click="$router.push('/safebox/create')">{{
        $t('SafeboxListing.create')
      }}</q-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { flattenDeep } from 'lodash-es'
import { Component, mixins } from 'vue-facing-decorator'

import appVersion from '@/app-version.json'
import Table from '@/classes/Table'
import Tuple from '@/classes/Tuple'
import InviteBanner from '@/components/InviteBanner.vue'
import SafeboxCard from '@/components/SafeboxCard.vue'
import SafeboxGrid from '@/components/SafeboxGrid.vue'
import SafeboxGroup from '@/components/SafeboxGroup.vue'
import SafeboxGroupCreationDialog from '@/components/SafeboxGroupCreationDialog.vue'
import SafeboxSearch from '@/components/SafeboxSearch.vue'
import { fetchTuples, tupleMutationEventBus } from '@/utils/api-functions'
import Imports from '@/utils/Imports'
import { SafeboxMixin } from '@/utils/SafeboxMixin'

@Component({
  name: 'SafeboxSelectionView',
  components: { SafeboxGroup, SafeboxGrid, SafeboxSearch, InviteBanner, SafeboxCard }
})
export default class SafeboxSelectionView extends mixins(SafeboxMixin) {
  appDrawer = true

  invites: Tuple[] = []
  groups: Tuple[] = []

  declare $refs: {
    search: SafeboxSearch
  }

  searchKeydownHandler!: (evt: KeyboardEvent) => void

  get nonGroupedSafeboxes() {
    return Imports.appStore.safeboxes.filter(tuple => !tuple.values.group?.v)
  }

  get showCreateButton() {
    return !this.$store.user!.attributes.parent
  }

  get version() {
    return appVersion.commit
  }

  async refreshMandates() {
    const tab = Table.getTable('invite')
    this.invites = flattenDeep([
      await fetchTuples(
        tab,
        new Tuple(tab, {
          status: tab.attributes.status.parseSingleStringValue('PENDING'),
          invitee: tab.attributes.status.parseSingleStringValue(this.$store.user!.name)
        })
      )
    ])
  }

  mounted() {
    this.searchKeydownHandler = (evt: KeyboardEvent) => {
      if (evt.ctrlKey && evt.key.toUpperCase() === 'F') {
        this.$refs.search.focus()
        evt.preventDefault()
      }
    }

    document.addEventListener('keydown', this.searchKeydownHandler)

    this.refresh()
  }

  beforeUnmount() {
    document.removeEventListener('keydown', this.searchKeydownHandler)
  }

  onSelect(safebox: Tuple) {
    Imports.appStore.selectSafebox(safebox.values.id.toString())
    this.$router.push('/dashboard')
  }

  refresh() {
    this.refreshSafeboxes()
    this.refreshMandates()
    this.refreshGroups()

    tupleMutationEventBus.on('*.safebox_group', () => this.refreshGroups())
  }

  async refreshGroups() {
    const tab = Table.getTable('safebox_group')!
    this.groups = [await fetchTuples(tab)].flat()
  }

  async createGroup() {
    const dialog = this.$q.dialog({
      component: SafeboxGroupCreationDialog
    })

    dialog.onOk(tuple => this.groups.push(tuple))
  }
}
</script>

<style scoped lang="scss">
.notifications {
  grid-area: notifications;
  align-self: start;
  justify-self: end;
  container: notifications / inline-size;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.version {
  margin-top: -1.5em;
  padding-left: 0.2em;
  color: #999;
  font-size: 0.8em;
  grid-column: 1;
  align-self: end;
}

.bottom-bar {
  grid-area: bottom;
  position: sticky;
  bottom: 0;
  padding-bottom: 1.5em;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  width: 100%;
  pointer-events: none;

  * {
    pointer-events: all;
  }
}

@media (max-width: 1300px) {
  .manage-safeboxes {
    background: white;
    box-shadow:
      0 1px 5px rgba(0, 0, 0, 0.2),
      0 2px 2px rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12);
  }
}

.root {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-areas: '. content notifications' 'bottom bottom bottom';
  grid-template-rows: auto min-content;
}
</style>

<style lang="scss">
.q-btn.manage-safeboxes,
.q-btn.create-group {
  color: #6f6f6f;
  margin: 0 1em;

  .q-icon {
    margin-right: 0.75em;
    font-size: 1em;
    position: relative;
    top: 1px;
  }
}

.manage-safeboxes {
  grid-column: 3;
  justify-self: end;
}

.create-group {
  grid-column: 1;
  justify-self: start;
}

.main-safebox-grid-container .safebox-list {
  width: auto;
  max-width: #{12em * 4 + 2em * 3 + 1.5em * 2};
}

.safeboxes {
  grid-area: content;
}
</style>
