<template>
  <div :class="['wrapper', { small }]" :data-row="row" :data-column="column">
    <q-card class="safebox-card" @click="onClick">
      <div class="background" :style="backgroundStyle" />
      <q-icon v-if="!small && canEdit" class="edit-button" name="fas fa-pen" @click.stop="openEditDialog">
        <q-tooltip :offset="[0, 5]">{{ $t('SafeboxCard.editTooltip') }}</q-tooltip>
      </q-icon>
      <SafeboxIndicators>
        <SafeboxOwnerIndicator :safebox="safebox" />
        <SafeboxShareIndicator :safebox="safebox" />
        <SafeboxActivityIndicator :safeboxes="[safebox]" />
        <SafeboxMessageIndicator :safeboxes="[safebox]" />
      </SafeboxIndicators>
    </q-card>
    <span class="safebox-name">
      {{ safebox.values.name.getDisplayValue() }}
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

import AccessType from '@/classes/AccessType'
import Table from '@/classes/Table'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import SafeboxActivityIndicator from '@/components/SafeboxActivityIndicator.vue'
import SafeboxEditDialog from '@/components/SafeboxEditDialog.vue'
import SafeboxIndicators from '@/components/SafeboxIndicators.vue'
import SafeboxMessageIndicator from '@/components/SafeboxMessageIndicator.vue'
import SafeboxOwnerIndicator from '@/components/SafeboxOwnerIndicator.vue'
import SafeboxShareIndicator from '@/components/SafeboxShareIndicator.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import { getBackgroundStyles } from '@/utils/safebox'

@Component({
  components: {
    SafeboxMessageIndicator,
    SafeboxActivityIndicator,
    SafeboxShareIndicator,
    SafeboxOwnerIndicator,
    SafeboxIndicators
  }
})
export default class SafeboxCard extends Vue {
  @Prop({ type: Object })
  safebox!: Tuple

  @Prop({ type: Boolean })
  small!: boolean

  @Prop({ type: Number })
  row!: number

  @Prop({ type: Number })
  column!: number

  access: Tuple[] = []

  get username() {
    return this.$store.user?.name
  }

  @Emit('select')
  private select(safebox?: Tuple) {
    return safebox ?? this.safebox
  }

  private onClick() {
    this.select()
  }

  created() {
    this.reload()
  }

  get canEdit(): boolean {
    if (!this.safebox) {
      return false
    }

    return this.safebox.tab.hasPermission(AccessType.EDIT, this.safebox)
  }

  async reload() {
    const response = await apiFetchJSON<TupleJSON[]>(`api/safebox/${this.safebox.getKeyString()}/safebox_access`)
    const tab = Table.getTable('safebox_access')
    this.access = await Promise.all(response.map(tuple => Tuple.fromJSON(tuple, tab)))
  }

  openEditDialog() {
    this.$q.dialog({
      component: SafeboxEditDialog,
      componentProps: {
        safebox: this.safebox
      }
    })
  }

  get backgroundStyle() {
    return getBackgroundStyles(this.safebox)
  }
}
</script>

<style scoped lang="scss">
@use 'sass:math';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: var(--group-duration);

  --size: var(--card-width, 12em);
}

.safebox-name {
  color: #6f6f6f;
  font-weight: 500;
  line-clamp: 3;
  margin-top: 1em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  text-align: center;
  width: 100%;
  max-width: var(--size);
  transition-duration: var(--group-duration);
  max-height: 4.5em;
  opacity: 1;

  & > span {
    padding-top: 1em;
    display: inline-block;
  }
}

$indicator-size: 64px;

.q-card {
  cursor: pointer;
  border-radius: 0.75rem;
  aspect-ratio: var(--card-aspect-ratio);
  position: relative;

  .background {
    inset: 0;
    position: absolute;
    border-radius: 0.75rem;
    background-size: cover;
    background-position: center;
  }

  width: var(--size);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .safebox-icon .q-icon {
    margin-top: 1rem;
    font-size: 5em;
    color: white;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.7));
  }
}

.safebox-icon {
  height: 100%;
  display: flex;
  align-items: end;
  justify-items: center;
  padding-bottom: 2.5em;
}

.safebox-name {
  :deep(.q-input .q-field__control),
  :deep(.q-input .q-field__marginal) {
    height: 1.75rem;
  }

  :deep(.q-input .q-field__append) {
    position: relative;
    top: 2px;
  }
}

.q-card:not(:hover) .edit-button {
  opacity: 0;
}

.edit-button {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.7)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 1));
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 9999px;
  opacity: 0.8;
  transition-duration: 150ms;

  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.1);
  }
}

.safebox-card {
  transition-duration: var(--group-duration);
}

.wrapper.small .indicators {
  transform: scale(0);
  opacity: 0;
}

.small .safebox-name {
  max-height: 0;
  opacity: 0;
  margin-top: 0;
}

/*.wrapper.small[data-row="0"][data-column="3"]
{
  margin-right: 5em;

  & ~ :deep(.more-placeholder) {
    margin-left: -6em;
    margin-right: 5em;
  }
}
*/

.wrapper[data-row='0'][data-column='2'] {
  position: relative;

  &::after {
    content: var(--more-label);
    position: absolute;
    inset: 0;
    width: 4em;
    height: 4em;
    line-height: 4em;
    text-align: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='0.75em' ry='0.75em' stroke='rgba(0,0,0,0.1)' stroke-width='5' stroke-dasharray='5 5' stroke-dashoffset='5' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
    opacity: 0;
    border-radius: 0.75em;
    transition-duration: 100ms;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 600;
  }
}

.wrapper.small[data-row='0'][data-column='2'] {
  &::after {
    transition-duration: var(--group-duration);
    transition-delay: var(--group-duration);
    opacity: 1;
  }
}

.wrapper.small[data-row='0'][data-column='3'],
.wrapper.small[data-row='0'][data-column='2'],
.wrapper.small:not([data-row='0']) {
  .safebox-card,
  .safebox-name {
    opacity: 0;
  }
}

/*
.wrapper.small:not([data-row="0"]) {
  transition-duration: var(--group-duration);
  margin-left: -1em;

  .safebox-card {
    transition-duration: var(--group-duration);
    width: 0;
  }

  .safebox-name {
    display: none;
  }
}*/
</style>
