<template>
  <q-dialog ref="dialog" @hide="onHide">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ $tt('title') }}</div>
      </q-card-section>
      <q-spinner v-if="stats === null" />
      <template v-else>
        <q-card-section>
          {{ $tt('contentDisclaimer', { ...stats, context: messageContext }) }}
        </q-card-section>
        <q-card-section v-if="stats.liteAccounts > 0">
          {{ $tt('liteAccountDisclaimer', stats) }}
        </q-card-section>
      </template>
      <q-card-actions>
        <q-btn secondary autofocus flat @click="$emit('hide')">{{ $tt('cancelButton') }}</q-btn>
        <div style="flex-grow: 1" />
        <q-btn secondary :disabled="!stats" @click="exportData">{{ $tt('exportButton') }}</q-btn>
        <q-btn color="red" :disabled="!stats" @click="deleteAccount">{{ $tt('deleteButton') }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { QDialog } from 'quasar'
import { Component, Vue } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import SafeboxExportDialog from '@/components/SafeboxExportDialog.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import Imports from '@/utils/Imports'
import { apiPath } from '@/utils/paths'

@Component({ emits: ['hide'] })
export class AccountDeletionDialog extends Vue {
  stats: { safeboxes: number; services: number; notes: number; liteAccounts: number } | null = null

  async created() {
    this.stats = await apiFetchJSON(`${apiPath}/stats`)
  }

  get messageContext() {
    if (this.isLiteUser) {
      return 'lite'
    } else {
      return 'proper'
    }
  }

  get isLiteUser() {
    return !!this.$store.user?.attributes?.parent
  }

  public show() {
    ;(this.$refs.dialog as QDialog).show()
  }

  public hide() {
    ;(this.$refs.dialog as QDialog).hide()
  }

  private onHide() {
    this.$emit('hide')
  }

  async exportData() {
    await this.$q.dialog({
      component: SafeboxExportDialog,
      componentProps: {
        preselect: Imports.appStore.safeboxes.map((s: Tuple) => s.values.id.v)
      }
    })
  }

  async deleteAccount() {
    await apiFetchJSON(`${apiPath}/profile/self`, { method: 'DELETE' })

    await this.$q.dialog({
      title: this.$tt('successTitle'),
      message: this.$tt('successMessage')
    })

    this.$router.push('/logout')
  }

  $tt(key: string, ...args: unknown[]) {
    // @ts-ignore
    return this.$t(`AccountDeletionDialog.${key}`, ...args)
  }
}

export default AccountDeletionDialog
</script>

<style scoped lang="scss"></style>
