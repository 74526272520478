<template>
  <div
    ref="list"
    :class="['safebox-list', { small, empty: safeboxes.length === 0 }]"
    :style="{
      '--rows': Math.ceil(safeboxes.length / 4),
      '--columns': Math.min(safeboxes.length, 4),
      '--more-label': safeboxes.length > maxDisplayedSafeboxes ? `'+${safeboxes.length - maxDisplayedSafeboxes}'` : undefined
    }"
  >
    <SafeboxCard
      v-for="(safebox, index) in safeboxes"
      :key="safebox.values.id.v"
      :safebox="safebox"
      :small="small"
      :row="Math.floor(index / 4)"
      :column="index % 4"
      @select="$emit('select', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useDraggable } from 'vue-draggable-plus'

import Tuple from '@/classes/Tuple'
import SafeboxCard from '@/components/SafeboxCard.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import Imports from '@/utils/Imports'
import { apiPath } from '@/utils/paths'

const props = defineProps<{ small?: boolean; group?: string }>()
const model = defineModel<Tuple[]>()

defineEmits<{ select: [safebox: Tuple] }>()

const safeboxIds = ref<number[]>([])
const list = ref<HTMLDivElement>()

const safeboxes = computed(() =>
  safeboxIds.value
    .map(id => Imports.appStore.safeboxes.find(safebox => safebox.values.id.v === id))
    .map(value => (value ? [value] : []))
    .flat()
)

const maxDisplayedSafeboxes = computed(() => (props.small ? 2 : 9999))

watch(
  () => model.value ?? Imports.appStore.safeboxes ?? [],
  newSafeboxes => {
    safeboxIds.value = newSafeboxes.map(tuple => tuple.values.id.v as number)
  }
)

const suppressAnimations = () => {
  if (list.value) {
    const el = list.value

    el.classList.add('suppress-animation')
    setTimeout(() => el.classList.remove('suppress-animation'), 150)
  }
}

const update = async () => {
  suppressAnimations()

  model.value = safeboxes.value

  await apiFetchJSON(`${apiPath}/update-safebox-order`, {
    method: 'POST',
    body: JSON.stringify({
      order: safeboxes.value.map(safebox => safebox.values.id.v),
      group: props.group ?? null
    })
  })

  Imports.appStore.safeboxes.forEach(safebox => {
    if (safeboxes.value.some(s => s.getKeyString() === safebox.getKeyString())) {
      safebox.setValue('group', props.group ?? null)
    }
  })
}

useDraggable(list, safeboxIds, {
  animation: 150,

  group: 'safebox-grid',

  onUpdate: update,
  onAdd: update,
  onRemove: suppressAnimations
})
</script>

<style scoped lang="scss">
.safebox-list {
  --gap: 2em;
  --card-width: 12em;
  --aspect-ratio: #{284 / 169};
  --card-height: calc(var(--card-width) * var(--aspect-ratio));
}

.safebox-list {
  width: calc(var(--columns) * var(--card-width) + (var(--columns) - 1) * var(--gap) + 2 * var(--padding));
}

.grid-container.small {
  width: #{3 * 4em + 2 * 1.5em + 2 * 1em};
}

.safebox-list.small {
  --gap: 1em;
  --card-width: 4em;
  --aspect-ratio: 1;
  --max-columns: 3;
}

.safebox-list {
  --padding: 1.5em;
  --max-columns: 4;

  padding: var(--padding);
  grid-area: content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: calc(var(--card-height) + 2 * var(--padding));
  gap: var(--gap);
  transition-duration: var(--group-duration);
  min-width: calc(12em + var(--padding) * 2);

  &.suppress-animation {
    transition-duration: unset;
  }

  &.empty {
    width: 100%;
  }

  flex-wrap: wrap;

  :deep(.sortable-ghost) {
    .safebox-card {
      background: none;
      box-shadow: none;
    }

    .background {
      background-color: transparent !important;
      // Temppu, jolla saadaan kustomoitua katkoviivareunuksen välitystä.
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='0.75em' ry='0.75em' stroke='rgba(0,0,0,0.1)' stroke-width='6' stroke-dasharray='10 10' stroke-dashoffset='10' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
    }

    .edit-button {
      display: none;
    }

    .indicators {
      transform: scale(0);
    }

    .safebox-name {
      opacity: 0;
    }
  }

  .q-card {
    cursor: pointer;
    border-radius: 0.75rem;
    background-color: lighten(#f1f2f4, 3);

    .safebox-name {
      color: #6f6f6f;
      font-size: 1rem;
    }
  }
}

.safebox-list.small .sortable-ghost {
  width: var(--card-width);
}

.safebox-list {
  --card-width: 12em;
  --card-aspect-ratio: #{169 / 248};
}

.safebox-list.small {
  --card-width: 4em;
  --card-aspect-ratio: 1;
}

.more-placeholder {
  position: relative;
  height: 4em;
  transition-duration: var(--group-duration);
  margin-left: calc(-1 * var(--gap));

  &::after {
    transition-duration: var(--group-duration);
    content: attr(data-text);
    position: absolute;
    left: var(--gap);
    width: 4em;
    height: 4em;
    text-align: center;
    line-height: 4em;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='0.75em' ry='0.75em' stroke='rgba(0,0,0,0.1)' stroke-width='5' stroke-dasharray='5 5' stroke-dashoffset='5' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
    border-radius: 0.75rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.2);
    padding-right: 0.1em;
    position: absolute;
  }
}
</style>
