<template>
  <div class="root">
    <div>
      <q-btn rounded size="sm" class="q-mb-lg" style="background-color: #fafafa" @click="onCancel"
        ><q-icon name="fa-solid fa-chevron-left" size="1em" class="q-mr-xs" />Peruuta</q-btn
      >
      <h1>{{ $t('RegistrationView.title') }}</h1>
      <p class="q-mt-lg q-mb-md q-te">{{ $t('RegistrationView.subtitle') }}</p>
      <ol>
        <li :class="['step', { completed: step > 0, active: step === 0 }]">
          <div class="step-completion-indicator"><q-icon name="fa-solid fa-check" /></div>
          <div class="step-line" />
          <span class="step-number">1.</span>
          <div class="step-icon">
            <img src="../images/file.svg" />
          </div>
          <div class="step-content">
            <div>{{ $t('RegistrationView.steps.acceptTermsOfService.title') }}</div>
            <div class="step-horizontal-line" />
          </div>
          <div class="step-description">
            <q-slide-transition v-show="step === 0">
              <q-card>
                <q-card-section>
                  <p>{{ $t('RegistrationView.steps.acceptTermsOfService.hstCardNeeded') }}</p>
                  <p>{{ $t('RegistrationView.steps.acceptTermsOfService.hstCardDataUsageDisclaimer') }}</p>
                  <ul>
                    <li>{{ $t('RegistrationView.steps.acceptTermsOfService.hstCardFields.satu') }}</li>
                    <li>{{ $t('RegistrationView.steps.acceptTermsOfService.hstCardFields.serialNumber') }}</li>
                    <li>{{ $t('RegistrationView.steps.acceptTermsOfService.hstCardFields.expiryDate') }}</li>
                    <li>{{ $t('RegistrationView.steps.acceptTermsOfService.hstCardFields.email') }}</li>
                  </ul>
                  <p class="q-mt-md">{{ $t('RegistrationView.steps.acceptTermsOfService.dataRetentionOnCancellationDisclaimer') }}</p>
                  <q-checkbox v-model="acceptTerms">
                    {{ $t('RegistrationView.steps.acceptTermsOfService.acceptTermsOfService') }}
                    <a href="#">{{ $t('RegistrationView.steps.acceptTermsOfService.termsOfService') }}</a>
                  </q-checkbox>
                  <q-checkbox v-model="acceptPrivacyStatement">
                    {{ $t('RegistrationView.steps.acceptTermsOfService.acceptPrivacyStatement') }}
                    <a :href="privacyStatementUrl" @click.stop>{{ $t('RegistrationView.steps.acceptTermsOfService.privacyStatement') }}</a>
                  </q-checkbox>
                  <q-checkbox v-model="acceptCookies">
                    {{ $t('RegistrationView.steps.acceptTermsOfService.acceptCookies') }}
                    <a href="#">{{ $t('RegistrationView.steps.acceptTermsOfService.cookies') }}</a>
                  </q-checkbox>
                </q-card-section>
                <q-card-section class="step-actions">
                  <q-btn rounded class="primary" :disable="!acceptTerms || !acceptCookies || !acceptPrivacyStatement" @click="step += 1">{{
                    $t('RegistrationView.nextStep')
                  }}</q-btn>
                </q-card-section>
              </q-card>
            </q-slide-transition>
          </div>
        </li>
        <li :class="['step', { completed: step > 1, active: step === 1 }]">
          <div class="step-completion-indicator"><q-icon name="fa-solid fa-check" /></div>
          <div class="step-line" />
          <span class="step-number">2.</span>
          <div class="step-icon">
            <img src="../images/dvv-logo.svg" />
          </div>
          <div class="step-content">
            <div class="step-content-text">{{ $t('RegistrationView.steps.authenticateWithHSTCard.title') }}</div>
            <div class="step-horizontal-line" />
          </div>
          <div class="step-description">
            <q-slide-transition v-show="step === 1">
              <q-card>
                <q-card-section>{{ $t('RegistrationView.steps.authenticateWithHSTCard.description') }}</q-card-section>
                <q-card-section class="step-actions">
                  <q-btn :loading="hstAuthInProgress" rounded class="primary" @click="hstAuth()">{{
                    $t('RegistrationView.steps.authenticateWithHSTCard.authenticate')
                  }}</q-btn>
                </q-card-section>
              </q-card>
            </q-slide-transition>
          </div>
        </li>
        <li :class="['step', { completed: step > 2, active: step === 2 }]">
          <div class="step-completion-indicator"><q-icon name="fa-solid fa-check" /></div>
          <div class="step-line" />
          <span class="step-number">3.</span>
          <div class="step-icon">
            <img src="../images/file.svg" />
          </div>
          <div class="step-content">
            <div>{{ $t('RegistrationView.steps.readAndSignUsageAgreement.title') }}</div>
            <div class="step-horizontal-line" />
          </div>
          <div class="step-description">
            <q-slide-transition v-show="step === 2">
              <q-card>
                <q-card-section>
                  <a href="#" style="display: flex; align-items: center; gap: 1em; justify-content: center; padding-top: 1em" @click="openTOS()">
                    <img src="../images/signed-file.svg" />
                    {{ $t('RegistrationView.steps.readAndSignUsageAgreement.read') }}
                  </a>
                </q-card-section>
                <q-card-section class="step-actions">
                  <q-btn :loading="hstSignInProgress" :disabled="!usageAgreementRead" rounded class="primary" @click="signTos()">{{
                    $t('RegistrationView.steps.readAndSignUsageAgreement.sign')
                  }}</q-btn>
                </q-card-section>
              </q-card>
            </q-slide-transition>
          </div>
        </li>
        <li :class="['step', { completed: step > 3, active: step === 3 }]">
          <div class="step-completion-indicator"><q-icon name="fa-solid fa-check" /></div>
          <div class="step-line" />
          <span class="step-number">4.</span>
          <div class="step-icon">
            <img src="../images/cryptokey-icon.svg" />
          </div>
          <div class="step-content">
            <div>{{ $t('RegistrationView.steps.addSecuritykey.title') }}</div>
            <div class="step-horizontal-line" />
          </div>
          <div class="step-description">
            <q-slide-transition v-show="step === 3">
              <div>
                <q-card class="substep">
                  <q-card-section horizontal>
                    <q-card-section :class="{ 'substep-completion-indicator': true, completed: createdCredential != null }">
                      <q-icon name="fa-solid fa-check" />
                    </q-card-section>
                    <q-card-section>
                      <div>{{ $t('RegistrationView.steps.addSecuritykey.description') }}</div>
                      <div class="substep-actions" style="display: flex; flex-direction: column; gap: 1em; align-items: center">
                        <q-btn :disable="createdCredential != null" rounded class="primary" @click="webauthnStart()">
                          {{ $t('RegistrationView.steps.addSecuritykey.add') }}
                        </q-btn>
                        <q-btn :disable="createdCredential != null" rounded class="primary" @click="mobileFlowStart()">
                          {{ $t('RegistrationView.steps.addSecuritykey.addUsingMobile') }}
                        </q-btn>
                      </div>
                    </q-card-section>
                  </q-card-section>
                </q-card>
                <q-card :class="{ substep: true, 'greyed-out': createdCredential == null }">
                  <q-card-section horizontal>
                    <q-card-section class="substep-completion-indicator">
                      <q-icon name="fa-solid fa-check" />
                    </q-card-section>
                    <q-card-section>
                      <div>{{ $t('RegistrationView.steps.addSecuritykey.description2') }}</div>
                      <div class="substep-actions">
                        <q-btn :disable="createdCredential == null" rounded class="primary" @click="webauthnFinish()">
                          {{ $t('RegistrationView.steps.addSecuritykey.auth') }}
                        </q-btn>
                      </div>
                    </q-card-section>
                  </q-card-section>
                </q-card>
              </div>
            </q-slide-transition>
          </div>
        </li>
        <li :class="['step', { completed: step > 4, active: step === 4 }]">
          <div class="step-completion-indicator" />
          <span class="step-number">{{ $t('RegistrationView.steps.finished.label') }}</span>
          <div class="step-icon" />
          <div class="step-content">
            <div>{{ $t('RegistrationView.steps.finished.title') }}</div>
            <div class="step-horizontal-line" />
          </div>
          <div class="step-description">
            <q-slide-transition v-show="step === 4">
              <q-card>
                <q-card-section>{{ $t('RegistrationView.steps.finished.description') }}</q-card-section>
                <q-card-section class="step-actions">
                  <q-btn rounded class="primary" :to="{ path: '/' }">{{ $t('RegistrationView.steps.finished.goToService') }}</q-btn>
                </q-card-section>
              </q-card>
            </q-slide-transition>
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import { Notification, NotificationLevel } from '@/classes/Notification'
import Tab from '@/classes/Tab'
import Tuple from '@/classes/Tuple'
import User, { registerUser } from '@/classes/User'
import MobileAuthenticationDialog from '@/components/MobileAuthenticationDialog.vue'
import PDFViewer from '@/components/PDFViewer.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import { base64ToArrayBuffer } from '@/utils/crypt'
import { createUserKey, getAesWrappingKeyFromCredential, webAuthnLogin, webauthnRegister } from '@/utils/crypt-utils'
import { apiPath, env } from '@/utils/paths'
import { createSafebox } from '@/utils/safebox'
import Signer from '@/utils/signer'
import { blobToBase64String } from '~blob-util'

interface CheckUserJSON {
  exists: boolean
  tosHash: string
  givenName: string
  familyName: string
}

@Component({})
export default class RegistrationView extends Vue {
  step = 0
  acceptTerms = false
  acceptPrivacyStatement = false
  acceptCookies = false
  signer!: Signer
  /** Palvelimella luotu satunnainen string, joka allekirjoitetaan ensimmäisessä tunnistautumisessa. */
  authNonce?: string
  /** Käyttöoikeussopimus tarkasteltavaksi */
  tosHashToInspect?: string
  /** Käyttöoikeussopimuksen hash allekirjoitettavaksi. */
  tosHashToSign?: string
  /** Tunnistautumisallekirjoitus */
  authSignature?: string
  /** Tunnistautuneen käyttäjän sertifikaatti */
  hstCert?: string
  /** Käyttöoikeussopimuksen allekirjoitus */
  tosSignature?: string
  /** Salauslaitteen allekirjoitus */
  securityKeySignature?: string
  /** Salauslaitteelle luotu credential */
  createdCredential: Credential | null = null
  /** Käyttäjän HST-sertifikaatista kaivettu etunimi */
  givenName?: string
  /** Käyttäjän HST-sertifikaatista kaivettu sukunimi */
  familyName?: string
  /** Näytetään spinner SCS-pyynnön aikana. */
  hstAuthInProgress = false
  /** Näytetään spinner SCS-pyynnön aikana. */
  hstSignInProgress = false
  /** Pidetään kirjaa siitä onko käyttäjä lukenut käyttöoikeussopimuksen. */
  usageAgreementRead = false

  privacyStatementUrl = new URL('../../assets/privacy-statement-fi.pdf', import.meta.url).href

  created() {
    this.signer = new Signer()
    apiFetchJSON<{ authNonce: string; tosHash: string }>('api/pre-register')
      .then(response => {
        this.authNonce = response.authNonce
        this.tosHashToInspect = response.tosHash
      })
      .catch(error => {
        console.error('Rekisteröinnin alustus epäonnistui: ', error)
        this.notify(new Notification(NotificationLevel.WARNING, this.$t('RegistrationView.steps.acceptTermsOfService.failedFetch')))
      })
  }

  onCancel() {
    this.$router.push('/')
  }

  openTOS() {
    if (this.tosHashToInspect) {
      const tosHashArrayBuffer = base64ToArrayBuffer(this.tosHashToInspect)
      const tosBlob = new Blob([tosHashArrayBuffer], { type: 'application/pdf' })
      const url = URL.createObjectURL(tosBlob.slice(0, tosBlob.size, 'application/pdf'))
      const title = 'Digua-käyttöoikeussopimus'
      this.$q.dialog({
        component: PDFViewer,
        componentProps: { url, title }
      })
    }
    this.usageAgreementRead = true
  }

  hstAuth() {
    this.hstAuthInProgress = true
    // Content (myös challenge_request) koostuu originista ja noncesta. Maksimipituus 1024 tavua. Noncen minimipituus 64 tavua.
    // Huom. Originin täytyy vastata sovelluksen URL:ää -> href välttämätön osa contentia
    this.signer
      .sign(window.btoa(`${window.location.href}${this.authNonce}`), 'digitalSignature', 'ec', 'data', 'SHA512', 'cms')
      .then(response => {
        if (response.status === 'ok') {
          this.authSignature = response.signature
          this.hstCert = response.chain![0]
          apiFetchJSON<CheckUserJSON>(apiPath + '/checkUser', {
            method: 'POST',
            body: JSON.stringify({
              hstCert: this.hstCert,
              authSignature: this.authSignature
            })
          })
            .then(resp => {
              if (!resp.exists) {
                this.tosHashToSign = resp.tosHash
                this.givenName = resp.givenName
                this.familyName = resp.familyName
                this.step += 1
              } else {
                this.notify(new Notification(NotificationLevel.WARNING, this.$t('RegistrationView.steps.authenticateWithHSTCard.hstAuthUserExists')))
              }
            })
            .finally(() => {
              this.hstAuthInProgress = false
            })
        } else {
          console.log('SCS-vastaus ei ok: ' + response.reasonText)
          this.notify(new Notification(NotificationLevel.WARNING, this.$t('RegistrationView.steps.authenticateWithHSTCard.hstAuthFailed'), response.reasonText))
          this.hstAuthInProgress = false
        }
      })
      .catch(error => {
        console.error('SCS-pyyntö epäonnistui', error)
        this.hstAuthInProgress = false
        if (error instanceof TypeError && error.message === 'Failed to fetch') {
          this.notify(
            new Notification(
              NotificationLevel.WARNING,
              this.$t('RegistrationView.steps.authenticateWithHSTCard.hstAuthFailed'),
              this.$t('RegistrationView.steps.authenticateWithHSTCard.hstAuthFailedNetworkError')
            )
          )
        } else {
          this.notify(new Notification(NotificationLevel.WARNING, this.$t('RegistrationView.steps.authenticateWithHSTCard.hstAuthFailed')))
        }
      })
  }

  signTos() {
    if (this.tosHashToSign) {
      this.hstSignInProgress = true
      this.signer
        .sign(this.tosHashToSign, 'nonRepudiation', 'rsa', 'digest', 'SHA256', 'cms')
        .then(response => {
          if (response.status === 'ok') {
            this.tosSignature = response.signature
            // HSTCertin ylikirjoittamisen sijaan käsittele authCert ja tosCert erillisinä?
            this.hstCert = response.chain![0]
            this.step += 1
          } else {
            console.log('SCS-pyyntö ei ok: ' + response.reasonText)
            this.notify(
              new Notification(NotificationLevel.WARNING, this.$t('RegistrationView.steps.authenticateWithHSTCard.hstSignFailed'), response.reasonText)
            )
          }
        })
        .catch(error => {
          console.error('SCS-pyyntö epäonnistui', error)
          this.notify(new Notification(NotificationLevel.WARNING, this.$t('RegistrationView.steps.authenticateWithHSTCard.hstSignFailed')))
        })
        .finally(() => {
          this.hstSignInProgress = false
        })
    }
  }

  get name() {
    let name = this.givenName + ' ' + this.familyName
    if (env?.toLowerCase() !== 'production') {
      name += ' dev'
    }
    return name
  }

  async mobileFlowStart() {
    const dialog = this.$q.dialog({
      component: MobileAuthenticationDialog,
      componentProps: {
        name: this.name
      }
    })

    dialog.onOk(async ({ key, authenticatorId }) => {
      await this.registerUser(key, authenticatorId)
      this.step = 4
    })
  }

  /** Aloittaa salauslaitteeseen lisäyksen. */
  async webauthnStart(): Promise<void> {
    const credential = await webauthnRegister(this.name, this.$store.user).catch(error => {
      console.error(error)
    })
    // @ts-ignore
    const results = credential?.getClientExtensionResults()
    console.debug('WebAuthn registration credential', credential, results)
    if (!credential) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed')))
      return
    }

    this.createdCredential = credential
  }

  async webauthnFinish(): Promise<void> {
    if (!this.createdCredential) {
      return
    }
    let userCryptKey: CryptoKey | null = null
    let largeBlob: ArrayBuffer | undefined

    // @ts-ignore
    const results = this.createdCredential.getClientExtensionResults()

    // Jos rekisteröitymisen yhteydessä ei saatu tarvittavia tietoja,
    // pyydetään käyttäjää tunnistautumaan uudelleen.

    if (!results.prf?.enabled) {
      // Jos selain ei tue PRF-laajennusta, luodaan käyttäjälle salausavain ja tallennetaan se
      // tunnistautumisen yhteydessä turva-avaimeen käyttäen largeBlob-laajennusta.
      userCryptKey = await createUserKey()
      largeBlob = await crypto.subtle.exportKey('raw', userCryptKey)
    }

    // Pyydetään käyttäjää tunnistautumaan.
    //  - Jos käytetään PRF-laajennusta, johdetaan saadusta vastauksesta salausavain.
    //  - Jos käytetään largeBlob-laajennusta, tunnistautumisen yhteydessä tallennetaan salausavain turva-avaimeen.
    //    Tässä tapauksessa emme tarvitse mitään tunnistautumisoperaation palauttamia tietoja, sillä avain on jo
    //    meidän hallussa.
    const credential = await webAuthnLogin([this.createdCredential.id], largeBlob).catch(error => {
      console.error(error)
      // Näytetään käyttäjälle geneerinen virheilmoitus seuraavassa ehtolauseessa
    })

    // @ts-ignore
    console.debug('WebAuthn login credential', credential, credential?.getClientExtensionResults())
    if (!credential) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('LandingPage.securityDeviceLoginFailed')))
      return
      // @ts-ignore
    } else if (!credential?.getClientExtensionResults()?.prf?.results?.first && !credential?.getClientExtensionResults()?.largeBlob?.written) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed'), this.$t('WebAuthnRegister.noPrfOrLargeBlob')))
      return
    }
    userCryptKey = userCryptKey ?? (await getAesWrappingKeyFromCredential(credential))
    await this.registerUser(userCryptKey, credential.id)
    this.step += 1
  }

  async registerUser(key: CryptoKey, authenticatorId: string) {
    const registerUserJSON = await registerUser(key, authenticatorId, this.hstCert!, this.authSignature!, this.tosSignature!, this.securityKeySignature!)
    const userJSON = registerUserJSON.user
    this.tosSignature = registerUserJSON.signedTos
    this.$store.$patch({ csrfToken: userJSON.csrfToken })
    const user = new User(userJSON, key, { id: authenticatorId } as Credential)
    await user.keyPair.get()
    this.$store.$patch({ user })
    await this.createFirstSafebox(user)
  }

  async createFirstSafebox(user: User) {
    const tab = Tab.getTab('safebox')!
    const name = user.attributes.full_name
    const username = user.name
    const safebox = Tuple.fromStrings({ name, username }, tab)
    const services: Tuple[] = []

    if (this.tosSignature) {
      const serviceAttachment = new Tuple(Tab.getTab('service_attachment')!)

      serviceAttachment.setValue('file', this.tosSignature)
      serviceAttachment.setValue('filename', 'Sopimus-allekirjoitettu.pdf')

      const service = new Tuple(Tab.getTab('service')!)
      service.setValue('name', 'Käyttöehtosopimus')
      service.setValue('notes', 'Tämä tietokortti sisältää allekirjoittamasi käyttöehtosopimuksen.')
      // Ei aseteta safeboxin uniqueId:tä serviceen (safebox valueksi) sen tuottaessa 400:n lisäyksessä.

      serviceAttachment.parent = service

      service.children.service_attachment = [serviceAttachment]
      service.parent = safebox

      services.push(service)
    }

    const privacyStatementAttachment = new Tuple(Tab.getTab('service_attachment')!)

    const privacyStatement = await (await fetch(this.privacyStatementUrl)).blob()

    privacyStatementAttachment.setValue('file', await blobToBase64String(privacyStatement))
    privacyStatementAttachment.setValue('filename', 'privacy-statement.pdf')

    const privacyStatementService = new Tuple(Tab.getTab('service')!)
    privacyStatementService.setValue('name', 'Tietosuojaseloste')
    privacyStatementService.setValue('notes', 'Tämä tietokortti sisältää palvelun tietosuojaselosteen.')

    privacyStatementAttachment.parent = privacyStatementService

    privacyStatementService.children.service_attachment = [privacyStatementAttachment]
    privacyStatementService.parent = safebox

    services.push(privacyStatementService)

    safebox.children.service = services

    await createSafebox(safebox, [username])
  }
}
</script>

<style scoped lang="scss">
$item-height: 6em;
$breakpoint: 1200px;
$step-count: 5;

.root {
  display: flex;
  justify-content: center;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
  hyphens: manual;
}

ol {
  display: grid;
  container: stepper / inline-size;
  grid-template-rows: repeat($step-count, calc($item-height / 3) calc($item-height / 3) calc($item-height / 3) min-content);
  grid-template-columns: 3em 2em 5em 30em 30em;
}

@media (width < #{$breakpoint}) {
  ol {
    grid-template-columns: 3em 2em 5em 30em;
  }

  .step-horizontal-line {
    display: none;
  }

  .step-description {
    margin-top: 1em;
  }
}

.step.active {
  .step-horizontal-line {
    opacity: 1;

    &::after {
      width: calc(100% - 2em);
    }
  }
}

.step-line {
  grid-column: 2 / span 1;
}

@for $index from 0 through $step-count {
  .step:nth-child(#{$index + 1}) {
    .step-completion-indicator,
    .step-icon {
      grid-row: #{$index * 4 + 1} / span 3;
      display: flex;
      align-items: center;
    }

    .step-content {
      grid-row: #{$index * 4 + 2} / span 1;
      display: flex;
      align-items: center;
    }

    .step-description {
      grid-row: #{$index * 4 + 3} / span 2;
      grid-column: 4;
    }

    @media (width >= #{$breakpoint}) {
      .step-description {
        grid-row: #{$index * 4 + 2};
        grid-column: 5;
        padding-top: 0;
      }
    }

    .step-number {
      grid-row: #{$index * 4 + 2} / span 1;
      display: flex;
      align-items: center;
      justify-self: center;
    }

    .step-line {
      grid-row: #{$index * 4 + 3} / span 3;
      grid-column: 2;
    }
  }
}

.step {
  display: contents;

  .step-number,
  .step-line,
  .step-icon,
  .step-content {
    opacity: 0.5;
  }

  &.active,
  &.completed {
    .step-number,
    .step-line,
    .step-icon,
    .step-content {
      opacity: 1;
    }
  }

  &.completed .step-completion-indicator .q-icon,
  .completed {
    color: #48cc1a !important;
  }

  .step-completion-indicator {
    font-size: 1.5rem;
    color: #b6b6b6;
    justify-self: center;
  }

  .step-icon {
    display: flex;
    justify-content: center;

    img {
      width: 2.5rem;
      justify-self: center;
      margin: 0 1em;
    }
  }

  .substep-completion-indicator {
    display: flex;
    font-size: 35px;
    color: #b6b6b6;
    justify-items: center;
    align-items: center;
    padding: 16px;
    margin-left: 16px;
  }

  .step-content {
    display: flex;
    align-items: center;
  }

  .step-horizontal-line {
    flex-grow: 1;
    min-width: 5em;
    flex-shrink: 0;
    opacity: 0;
    background: #5e5d5e;
  }

  .step-horizontal-line::after {
    width: 0;
    height: 0.3em;
    top: 50%;
    margin-top: -0.15em;
    margin-left: 1em;
    transition: width 200ms;
  }

  .step-line,
  .step-horizontal-line {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      border-radius: 9999px;
      background-color: #00d0f1;
    }
  }

  &.completed {
    .step-line::after {
      height: 100%;
    }
  }

  .step-line {
    min-height: 2em;
    position: relative;
    background-color: #b6b6b6;
    width: 0.3em;
    margin: 0.5em 0;
    border-radius: 9999px;
    justify-self: center;

    &::after {
      content: '';
      position: absolute;
      background-color: #00d0f1;
      left: 50%;
      margin-left: -0.15em;
      transition: height 200ms;
      height: 0;
      width: 100%;
    }
  }

  .step-number {
    font-size: 1.5em;
    font-weight: 700;
  }

  .q-card {
    border-radius: 10px;
  }
}

.step-actions {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.substep {
  margin-bottom: 16px;
}

.substep-actions {
  display: flex;
  justify-content: center;
  margin-left: -83px;
  padding-top: 16px;
}

.greyed-out {
  opacity: 0.6;
}

.q-btn.primary {
  background: linear-gradient(to bottom, #00d0f1, #048ed3);
  color: white;
  font-weight: 600;
  text-transform: none;
  padding: 0 1em;
  line-height: 0.75em;
}

.q-btn.cancel {
  color: #535353;
  font-weight: 600;
  height: 2.5em;
  text-transform: none;
  padding: 0 0.5em;
}

a {
  color: #048ed3;
  font-weight: 700;
}
</style>
