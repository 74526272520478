// eslint-disable-next-line vue/prefer-import-from-vue
import { enableTracking, pauseTracking } from '@vue/reactivity'
import dayjs from 'dayjs'
import { ComponentPublicInstance } from 'vue'
import { Router } from 'vue-router'

import BaseError from '@/classes/errors/BaseError'
import TechnicalError from '@/classes/errors/TechnicalError'
import { app } from '@/create-app'
import { apiFetchJSON } from '@/utils/api-functions'
import { useKantoStore } from '@/utils/store'

/**
 * Muodostaa tarvittaessa TechnicalErrorin Kannon virhekäsittelyn ulkopuolisesta Error-oliosta.
 */
function wrapError(err?: unknown): BaseError {
  if (err === undefined || err === null) {
    // Itse virhe on jostain syystä tyhjä. Itse ongelma on ylempänä jo lokitettu, joten luodaan vain uusi dummy-virhe, jotta saadaan virheilmoitus näytettyä käyttäjälle.
    return new TechnicalError('Tyhjä virhe viheenkäsittelyssä.')
  } else if (err instanceof BaseError) {
    return err
  } else if (err instanceof Error) {
    // Wrapataan TechnicalErroriin, jotta saadaan mahdolliset lisätiedot matkaan.
    return new TechnicalError(err.message, err)
  } else {
    // Wrapataan TechnicalErroriin, jotta saadaan esitettyä tuntematon virhe
    return new TechnicalError(String(err))
  }
}

const processedErrors: BaseError[] = []

/** Tyhjentää käsiteltyjen virheiden listan, jotta samat virheet voidaan mahdollisesti näyttää uudestaan. */
export function clearProcessedErrors() {
  processedErrors.length = 0
}

/**
 * Virheen esittäminen käyttäjälle.
 */
export function actionOnError(err: BaseError): void {
  if (processedErrors.some(e => e.message === err.message)) {
    // Lokitetaan kukin virhe vain kertaalleen, jotta virheenkäsittelyn virheet eivät aiheuta loputonta looppia.
    console.warn('Virhe on jo käsitelty aiemmin', err)
    return
  }
  processedErrors.push(err)
  const store = useKantoStore()
  if (err.message?.startsWith('Failed to fetch dynamically imported module') || err.message?.startsWith('Importing a module script failed')) {
    // Dynaamisesti ladatun moduulin lataaminen epäonnistui, eli todennäköisesti frontendin versio on vanhentunut.
    store.$patch({ updateRequired: true })
  } else {
    store.errors.push(err)
  }
  let body: BodyInit
  try {
    // Haetaan arvot storesta ilman reaktiivisuutta, jotta storen myöhemmät muutokset eivät aiheuta tämän virheen uudelleenkäsittelyä.
    // Tämän saisi toivottavasti tehtyä jotenkin siistimmminkin.
    pauseTracking()
    body = JSON.stringify({
      user: store.user?.name,
      timestamp: dayjs(),
      userAgent: navigator.userAgent,
      sessionExpires: store.sessionExpires,
      error: err,
      tuple: store.tuple
    })
  } finally {
    enableTracking()
  }
  // Viedään virhe backendille lokitettavaksi
  apiFetchJSON('log-frontend-error', {
    method: 'PUT',
    body
  }).catch(fetchErr => console.error('Virheen lokitus backendiin epäonnistui', fetchErr))
}

/**
 * Muodostaa tiedon annetun komponentin isäkomponenteista.
 */
function getComponentNameChain(component: ComponentPublicInstance): string | undefined {
  return component.$parent ? getComponentNameChain(component.$parent) + ' -> ' + component.$options.name : component.$options.name
}

/**
 * Vuen ulkopuolisten virheiden käsittely.
 */
window.onerror = (message, source, line, column, error): void => {
  console.error('Virhe sovelluksessa (onerror): ' + message, source, line, column, error instanceof TechnicalError ? error.innerError ?? error : error)
  actionOnError(wrapError(error ?? new TechnicalError(message.toString())))
}

/**
 * Promise-virheiden käsittely.
 */
window.onunhandledrejection = (event: PromiseRejectionEvent): void => {
  console.error('Virhe sovellukseessa (onunhandledrejection): ', event.reason, event.reason?.innerError)
  event.preventDefault()
  let err: BaseError
  if (event.reason instanceof Error) {
    err = wrapError(event.reason)
  } else {
    err = new TechnicalError('Tuntematon unhandledrejection.')
    err.info = String(event.reason)
  }
  actionOnError(err)
}

/**
 * Vuen virheiden käsittely.
 *
 * @param err error trace
 * @param instance component in which error occured
 * @param info Vue specific error information such as lifecycle hooks, events etc.
 */
app.config.errorHandler = (err: unknown, instance: ComponentPublicInstance | null, info: string): void => {
  const componentName = instance ? getComponentNameChain(instance) : undefined
  console.error(`Virhe sovelluksessa. Komponentti: ${componentName}, info: ${info}\n`, err, instance)

  const baseErr = wrapError(err)
  baseErr.vueComponentName = componentName
  baseErr.info = info
  actionOnError(baseErr)
}

/**
 * Vue-routerin virheiden käsittely.
 */
export function addRouterErrorHandler(router: Router) {
  router.onError((err: Error): void => {
    console.error('Virhe sovelluksessa (router): ', err)
    actionOnError(wrapError(err))
  })
}
